@font-face {
	font-family: "Gotham";
	src: url("../assets/fonts/gothampro.ttf") format("truetype");
	font-weight: 500;
}

@font-face {
	font-family: "Gotham";
	src: url("../assets/fonts/GothaProReg.ttf") format("truetype");
	font-weight: 700;
}

@font-face {
	font-family: "Druk";
	src: url("../assets/fonts/druktextwidecyr.otf") format("truetype");
	font-stretch: 200%;
	font-weight: 700;
}

@font-face {
	font-family: "Lato";
	src: url("../assets/fonts/Lato-Regular.ttf") format("truetype");

	font-weight: 400;
}
