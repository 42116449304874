@import "$colors";

header {
  margin-top: 12px;
  .container{
    background-color: $paperBackgroundColor;
    border-radius: 16px;
  }

  .emailName {
    .MuiTypography-root {
      max-width: 140px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
