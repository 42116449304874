  .alert-window-container {
      padding: 50px;
  }

  .alert-button {
      background: #5606FF;
      padding: 18.5px;
      font-family: "Gotham Pro", sans-serif;
      color: white;
      border-radius: 6px;

      @media screen and (max-width: 430px) {
          margin-top: 20px;
      }

      &[disabled] {
          opacity: 0.5;
      }
  }