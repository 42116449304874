@import "bootstrap.override";
@import "fonts";

* {
  // font-stretch: 200%;
}

.css-13smzqp input {
  color: black !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border: 1px solid white;
  border-radius: 9px;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background: white;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-copy {
  cursor: copy;
}

.user-select-none {
  user-select: none;
}

.pointer-event-none {
  pointer-events: none !important;
}

.hover-scale {
  &:hover {
    transition: all 0.1s linear;
    transform: scale(1.015);
  }
}

body,
html {
  padding: 0 !important;
  overflow-x: hidden;
}

.element-animation {
  /* Скроем элемент в начальном состоянии */
  opacity: 0;
  transform: translateY(20px);
}

.element-animation.element-show {
  opacity: 1;
  transition: all 1.5s;
  transform: translateY(0%);
}

.background-king {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 55%;
  background-image: url("../assets/img/wizzard-opacity.png");
}

.card-grid {
  margin: 55px 0 55px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 55px;
  width: fit-content;
  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
    margin: 24px 0 24px 0;
  }
}

.text-ellipsis {
  word-wrap: normal;
  text-overflow: ellipsis;
  overflow: hidden;
}

body {
  margin: 0;
  background: linear-gradient(180deg, $backgroundColor 50%, $primary 100%);

  #webpack-dev-server-client-overlay {
    display: none !important;
  }
}

.mobile-center {
  @media only screen and (max-width: 600px) {
    justify-content: center;
    align-items: center;
  }
}

.action-button {
  position: relative;

  &:hover {
    transition: all 0.1s linear;
    transform: scale(1.06);
  }
  &__icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.signup {
  a {
    color: white !important;
  }
}
