@import "colors.module";

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

$theme-colors: (
  primary: $primary,
  secondary: $secondary,
);

@import '~bootstrap/scss/bootstrap';

.br-16{
  border-radius: 16px !important;
}

.br-6{
  border-radius: 6px !important;
}

.br-8{
  border-radius: 8px !important;
}

.w-fit{
  width: fit-content;
}

.h-fit{
  height: fit-content;
}

.mw-50{
 max-width: 50%;
}

.mw-75{
  max-width: 75%;
}

.flex-1{
  flex: 1 1
}

.ellipsis{
  text-overflow: ellipsis;
  overflow: hidden;
}

.card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0;
}

.absolute-center{
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
